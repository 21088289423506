import React, { useMemo, ReactNode } from 'react';
import _ from 'lodash';

type args = {
	value?: string;
	validation?: Validation.Array;
};

type ret = [boolean, ReactNode[]];

export const useValidation = ({ value, validation = [] }: args) =>
	useMemo<ret>(() => checkValid(value, validation), [value, validation]);

// export const DEPRECATED_validate = ({ value, validation = [] }: args): ret => {
// 	process.env.NODE_ENV === 'development' &&
// 		console.warn(
// 			"DEPRECATED_validate is deprecated, please switch to 'checkValid'."
// 		);
// 	if (value == null || validation == null) return [true, []];

// 	const errorMessage: ReactNode[] = [];

// 	return [
// 		_.every(validation, v => {
// 			const isMatch = v.pattern.test(value);

// 			if (isMatch == false) errorMessage.push(v.message);

// 			return isMatch;
// 		}),
// 		errorMessage,
// 	];
// };

export const checkValid = (
	value: args['value'],
	validation: args['validation'] = []
): ret => {
	if (value == null || validation == null) return [true, []];

	const errorMessage: ReactNode[] = [];

	return [
		_.every(validation, v => {
			const isMatch = v.pattern.test(value);

			if (isMatch == false) errorMessage.push(v.message);

			return isMatch;
		}),
		errorMessage,
	];
};

/** @deprecated Deprecated for direct usage, switch to xValidate instead. */
export const checkForErrors = (
	value: args['value'],
	validation: args['validation'] = []
): ret[1] => {
	if (value == null || validation == null) return [];

	return _(validation)
		.map(v => {
			const isMatch = v.pattern.test(value);

			if (isMatch == false) return v.message;
		})
		.compact()
		.value();
};
