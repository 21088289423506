import _ from 'lodash';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { FC, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { AsyncTypeahead, AsyncTypeaheadProps } from 'react-bootstrap-typeahead';
import { FormFeedback } from 'reactstrap';
import { useXValidate, xBaseDefaultState, xBaseDefaultStateType, xBaseSetup, xBaseState } from './XBase';

export type autocompleteOption = {
	id: string;
	label: string;
	$transport?: { [key: string]: string | null | undefined };
	$other?: { [key: string]: string | null | undefined };
};

// ===== state (class version) =================================================
// ===== state (class version) =================================================
// ===== state (class version) =================================================

export type xAutocompleteSetup = xBaseSetup & {
	$options?: autocompleteOption[];
	$isLoading?: boolean;
	$selectedOption?: autocompleteOption;
	$onQuery?: (query: string) => Promise<autocompleteOption[]>;
};

export class xAutocompleteState extends xBaseState {
	@observable $options?: autocompleteOption[];
	@observable $isLoading?: boolean;
	@observable $selectedOption?: autocompleteOption;
	@observable $onQuery = async (query: string): Promise<autocompleteOption[]> => [];

	constructor(setup?: xAutocompleteSetup) {
		super(setup);
		if (setup) {
			_.assign(this, _.pick(setup, ['$options', '$isLoading', '$selectedOption', '$onQuery']));
		}
	}

	@action $withOnQuery(onQuery: this['$onQuery']): this {
		this.$onQuery = onQuery;
		return this;
	}
}

// ===== state (old deprecated version) ========================================
// ===== state (old deprecated version) ========================================
// ===== state (old deprecated version) ========================================

/** @deprecated Use the xAutocompleteState class instead. */
export interface autocompleteState extends xBaseDefaultStateType<autocompleteState> {
	$options?: autocompleteOption[];
	$isLoading?: boolean;
	$selectedOption?: autocompleteOption;
	$onQuery: (query: string) => Promise<autocompleteOption[]>;
	$withOnQuery: ($onQuery: autocompleteState['$onQuery']) => autocompleteState;
}

/** @deprecated Use the xAutocompleteState class instead. */
export const xAutocompleteDefaultInitialState: autocompleteState = {
	...xBaseDefaultState,
	async $onQuery() {
		return [];
	},
	$withOnQuery($onQuery) {
		return { ...this, $onQuery };
	},
};

// ===== component - autocomplete ==============================================
// ===== component - autocomplete ==============================================
// ===== component - autocomplete ==============================================

type XAutocomplete = { stateRef?: autocompleteState | xAutocompleteState } & Partial<AsyncTypeaheadProps<autocompleteOption>>;
export type XAutocompleteHandler = {
	blur(): void;
	focus(): void;
	clear(): void;
	input: HTMLInputElement;
};

export const XAutocomplete = observer<XAutocomplete, XAutocompleteHandler>(
	({ stateRef: state = useState(() => new xAutocompleteState())[0], ...inputProps }, ref) => {
		const typeRef = useRef<any>();
		useXValidate(state);
		useImperativeHandle(ref, () => ({
			blur() {
				typeRef.current.getInstance().blur();
			},
			focus() {
				typeRef.current.getInstance().focus();
			},
			clear() {
				state.$selectedOption = void 7;
				state.value = '';
				typeRef.current.getInstance().clear();
			},
			get input() {
				return typeRef.current.getInstance().getInput();
			},
		}));

		const [tempId] = useState(_.uniqueId);

		return (
			<>
				<AsyncTypeahead<autocompleteOption>
					{...inputProps}
					ref={ins => (typeRef.current = ins)}
					id={tempId}
					options={state.$options || []}
					disabled={state.$disabled}
					isInvalid={state.$touched && state.errors.length > 0}
					isLoading={!!state.$isLoading}
					selected={state.$selectedOption ? [state.$selectedOption] : []}
					onSearch={async query => {
						state.$isLoading = true;
						state.$options = await state.$onQuery(query);
						state.$isLoading = false;
					}}
					onBlur={e => {
						inputProps.onBlur && inputProps.onBlur(e);
						state.$touched = true;
					}}
					onInputChange={(i, e) => {
						inputProps.onInputChange && inputProps.onInputChange(i, e);
						state.$touched = true;
					}}
					onChange={e => {
						inputProps.onChange && inputProps.onChange(e);
						state.$touched = true;
						state.value = e[0] ? e[0].label : '';
						state.$selectedOption = e[0];
					}}
				/>
				{state.$touched &&
					state.errors.map((m, k) => (
						<FormFeedback key={k} className='d-block'>
							{m}
						</FormFeedback>
					))}
				{(!state.$touched || state.errors.length < 1) && <FormFeedback className='d-block'>&nbsp;</FormFeedback>}
				<div className='mb-2' />
			</>
		);
	},
	{ forwardRef: true }
);
