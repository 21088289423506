import 'bootstrap-datepicker';
import $ from 'jquery';
import { observer, useLocalStore } from 'mobx-react-lite';
import moment from 'moment';
import React, { ComponentProps, FC, ReactNode, useLayoutEffect, useRef } from 'react';
import { FormFeedback, Input, InputGroup } from 'reactstrap';
import { useXValidate, xBaseDefaultState, xBaseDefaultStateType, xBaseSetup, xBaseState } from './XBase';
// @ts-ignore
// require('bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css');

// ===== state (class version) =================================================
// ===== state (class version) =================================================
// ===== state (class version) =================================================
export type xInputSetup = xBaseSetup;
export class xInputState extends xBaseState {}
export class xDatepickerState extends xInputState {}

// ===== state (old deprecated version) ========================================
// ===== state (old deprecated version) ========================================
// ===== state (old deprecated version) ========================================

/** @deprecated Use the xInputState class instead. */
export interface textboxState extends xBaseDefaultStateType {}

/** @deprecated Use the xInputState class instead. */
export const xInputDefaultInitialState: textboxState = xBaseDefaultState;

type Props = {
	inputGroupClassName?: string;
	stateRef?: textboxState | xInputState;
	prepend?: ReactNode;
	append?: ReactNode;
} & ComponentProps<typeof Input>;

// ===== component - textbox ===================================================
// ===== component - textbox ===================================================
// ===== component - textbox ===================================================

export const XTextbox: FC<Props> = observer(
	({ stateRef: current = useLocalStore(() => new xInputState()), prepend, append, inputGroupClassName, ...inputProps }) => {
		useXValidate(current);

		return (
			<>
				<InputGroup className={inputGroupClassName}>
					{prepend}
					<Input
						disabled={current.$disabled}
						invalid={current.$touched && current.errors.length > 0}
						value={current.value}
						onBlur={() => {
							current.$touched = true;
							current.$applyPostTransform();
						}}
						onChange={e => {
							current.value = e.currentTarget.value;
							current.$touched = true;
						}}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								e.preventDefault();
								e.currentTarget.blur();
							}
						}}
						{...inputProps}
					/>
					{append}
				</InputGroup>
				{current.$touched &&
					current.errors.map((m, k) => (
						<FormFeedback key={k} className='d-block'>
							{m}
						</FormFeedback>
					))}
				{(!current.$touched || current.errors.length < 1) && <FormFeedback className='d-block'>&nbsp;</FormFeedback>}
				<div className='mb-2' />
			</>
		);
	}
);

// ===== component - datepicker ================================================
// ===== component - datepicker ================================================
// ===== component - datepicker ================================================

type DatepickerProps = {
	inputGroupClassName?: string;
	stateRef?: textboxState | xDatepickerState;
	prepend?: ReactNode;
	append?: ReactNode;
} & ComponentProps<typeof Input>;

export const XDatepicker: FC<Props> = observer(({ stateRef: current = useLocalStore(() => new xInputState()), ...inputProps }) => {
	const ref = useRef<HTMLInputElement>(null);

	useLayoutEffect(() => {
		if (ref.current) {
			$(ref.current)
				.datepicker({
					format: 'dd/mm/yyyy',
				})
				.on('changeDate', ({ date }) => {
					current.value = moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY');
					current.$touched = true;
				})
				.on('hide', () => {
					current.$touched = true;
				})
				.on('clearDate', () => {
					current.value = '';
				});

			if (current.value) {
				$(ref.current).datepicker('setDate', moment(current.value, 'DD/MM/YYYY').toDate());
			}
		}

		return () => {
			ref.current && $(ref.current).datepicker('destroy');
		};
	}, []);

	useLayoutEffect(() => {
		if (ref.current) {
			if (current.value) {
				$(ref.current).datepicker('setDate', moment(current.value, 'DD/MM/YYYY').toDate());
			}
		}
	}, [current.value]);

	useXValidate(current);

	return (
		<>
			<Input innerRef={ref} disabled={current.$disabled} invalid={current.$touched && current.errors.length > 0} {...inputProps} />
			{current.$touched &&
				current.errors.map((m, k) => (
					<FormFeedback key={k} className='d-block'>
						{m}
					</FormFeedback>
				))}
			{(!current.$touched || current.errors.length < 1) && <FormFeedback className='d-block'>&nbsp;</FormFeedback>}
			<div className='mb-2' />
		</>
	);
});
