import { useContext } from 'react';

export const useContextOrError = <T extends any>(
	context: React.Context<T | null>,
	errorMsg: string = `Missing Context: ${context.displayName}`
): T => {
	const contextValue = useContext(context);
	if (contextValue != null) {
		return contextValue;
	} else {
		throw new Error(errorMsg);
	}
};
