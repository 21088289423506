import React, { FC, ComponentPropsWithoutRef } from 'react';
import * as BS from 'reactstrap';

export const Page: FC<{
	row?: ComponentPropsWithoutRef<typeof BS.Row>;
	col?: ComponentPropsWithoutRef<typeof BS.Col>;
}> = ({ row, col, children }) => (
	<BS.Row {...row}>
		<BS.Col {...col}>{children}</BS.Col>
	</BS.Row>
);
