import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useLayoutEffect } from 'react';
import { classes, style } from 'typestyle';

type props = {
	className?: string;
	step: number;
	steps: string[];
	hasErrors?: number[];
	hideSteps?: number[];
	onClick: (value: number) => void;
};

const flexBasis0 = style({ $debugName: 'flexBasis0', flexBasis: 0 });

export const Tabs: React.FC<props> = props => {
	const findErrorIndex = (index: number) => {
		if (props.hasErrors && props.hasErrors.length) {
			return _.indexOf(props.hasErrors, index) > -1;
		}
		return false;
	};

	useLayoutEffect(() => {
		if ((props.hideSteps?.indexOf(props.step) ?? -1) > -1) {
			props.onClick(1);
		}
	}, [props.step]);

	return (
		<div className={classes('Tabs', props.className)}>
			{props.steps.map((value, index) => {
				const hidden = (props.hideSteps?.indexOf(index + 1) ?? -1) > -1;
				if (hidden) return null;

				const isActive = index + 1 == props.step;
				const hasError = findErrorIndex(index + 1);

				return (
					<button
						key={index}
						className={classes('Tab', isActive && 'selected', hasError && 'error')}
						onClick={e => {
							e.preventDefault();
							props.onClick(index + 1);
							e.currentTarget.blur();
						}}
					>
						{value}
						{hasError && (
							<>
								<span className='px-1' />
								<FontAwesomeIcon fixedWidth icon={faExclamationCircle} />
							</>
						)}
					</button>
				);
			})}
		</div>
	);
};
