export type BaseRes<A extends {} = {}> = {
	success?: boolean;
	messsage?: string;
} & Partial<A>;
export const Authorization = 'Basic b255eDpvbnl4MTIzIUA=';

export const getHeaders = {
	Accept: 'application/json',
	Authorization,
};
export const postHeaders = {
	'Content-Type': 'application/json',
	Authorization,
};


export const APIRes = Promise;

export type APIRes<DataType extends any = undefined, Others extends {} = {}> = Promise<Partial<
	{
		success: boolean;
		message: string;
		data?: DataType extends (infer ArrayItem)[]
			? ArrayItem extends {}
				? RecursivePartial<ArrayItem>[]
				: ArrayItem[]
			: DataType extends {}
			? RecursivePartial<DataType>
			: DataType;
	} & RecursivePartial<Others>
> | null>;

export type DataOf<F extends () => APIRes<any>> = NonNullable<Unwrap<F>>['data'] | null;
