import { flatMap, memoize, uniq } from 'lodash';
import { css } from "styled-components";
import { makeGrid, placeAt } from "./GridUtils.sc";

export function gridExtract(template: string) {
	const [preSlash, postSlash] = template.split(/\s*\/\s*/);

	const rows = preSlash.split(/\s*['"]\s*/).filter(x => x.length > 1);
	const [preCells, heights] = rows.reduce(([cells, heights], s, i) => {
		if (i % 2 === 0) {
			return [cells.concat(s), heights];
		} else {
			return [cells, heights.concat(s.trim())];
		}
	}, [[] as string[], [] as string[]]);

	const cells = preCells.map(r => r.split(/\s+/).map(c => c.trim()));
	const widths = postSlash.split(/\s+/).filter(h => h.length > 1);


	return {
		cells,
		cols: widths.join(" "),
		rows: heights.join(" ")
	};
}

export function gridFind(grid: string[][], area: string) {
	let colStart = -1;
	let colEnd = -1;
	let rowStart = -1;
	let rowEnd = -1;

	grid.forEach((row, index) => {
		const firstFound = row.indexOf(area);
		const lastFound = row.lastIndexOf(area);
		if (firstFound > -1) {
			if (colStart == -1) colStart = firstFound + 1;
			if (rowStart == -1) rowStart = index + 1;
		}
		if (lastFound > -1) {
			colEnd = lastFound + 1;
			rowEnd = index + 1;
		}
	});

	return {
		col: colStart,
		row: rowStart,
		colSpan: colEnd - colStart + 1,
		rowSpan: rowEnd - rowStart + 1,
	};
}

export function simpleGrid(str: TemplateStringsArray, ...args: any[]) {
	const string = str.reduce((memo, val, i) => memo + (val ?? '') + (args[i] ?? ''), '').trim();
	const { cells, ...config } = gridExtract(string);
	return {
		makeGrid: memoize(() => makeGrid(config)),
		placeAt: memoize((area: string) => placeAt(gridFind(cells, area))),
		get cells() {
			return uniq(flatMap(cells)).filter(x => x !== '.');
		}
	};
}

export type GridUtil = ReturnType<typeof grid> | ReturnType<typeof simpleGrid>;

export function grid(str: TemplateStringsArray, ...args: any[]) {
	const string = str.reduce((memo, val, i) => memo + (val ?? '') + (args[i] ?? ''), '').trim();
	const cellsAndMedia = string.replace(/@media/g, '}@media')
		.split(/\s*[{}]\s*/)
		.filter(x => x.length > 0)
		.reduce((memo, val, i, ary) => {
			// skip @media
			if (/^\s*@media/.test(val)) return memo;
			// if prev is @media
			if (/^\s*@media/.test(ary[i - 1])) {
				return [...memo, { gridStr: val, media: ary[i - 1] }];
			}
			return [...memo, { gridStr: val }];
		}, [] as { gridStr: string, media?: string; }[]);

	const gridsAndMedia = cellsAndMedia.map(({ gridStr, media }) => {
		return { grid: gridExtract(gridStr), media };
	});

	return {
		makeGrid: memoize(() => gridsAndMedia.map(({ grid, media }) => {
			const { cells, ...config } = grid;
			if (media) {
				return css`${media} { ${makeGrid(config)} }`;
			} else {
				return makeGrid(config);
			}
		})),

		placeAt: memoize((area: string) => gridsAndMedia.map(({ grid, media }) => {
			if (media) {
				return css`${media} { ${placeAt(gridFind(grid.cells, area))} }`;
			} else {
				return placeAt(gridFind(grid.cells, area));
			}
		})),
		get cells() {
			return uniq(flatMap(gridsAndMedia, ({ grid }) => flatMap(grid.cells))).filter(x => x !== '.');
		}
	};
}