import styled, { css } from "styled-components";

export const Wrapper = styled.div<{ fullHeight?: boolean; }>`
	position: relative;
	width: 100%;
	${({ fullHeight }) => fullHeight && css`height: 100%;`}
	display: flex;
	flex-direction: column;
	justify-items: center;
	justify-content: center;
`;

export const Outside = styled.div<{ active?: boolean; }>`
	${props => props.active && css`
		position: absolute;
		top: 100%;
	`}
`;