import { css } from 'styled-components';

export function placeAt({ col, colSpan = 1, row, rowSpan = 1 }: { col: number, colSpan?: number, row: number, rowSpan?: number; }) {
	return css`
		-ms-grid-column: ${col};
		-ms-grid-column-span:${colSpan};
		-ms-grid-row: ${col};
		-ms-grid-row-span: ${rowSpan};

		grid-column: ${col} / span ${colSpan};
		grid-row: ${row} / span ${rowSpan};
	`;
}

export function makeGrid({ cols, colsRepeat = 1, rows, rowsRepeat = 1 }: { cols: string, colsRepeat?: number, rows: string, rowsRepeat?: number; }) {
	return css`
		display: -ms-grid;
		display: grid;
		-ms-grid-columns: (${cols})[${colsRepeat}];
		-ms-grid-rows: (${rows})[${rowsRepeat}];

		grid-template-columns: repeat(${colsRepeat}, ${cols});
		grid-template-rows: repeat(${rowsRepeat}, ${rows});
	`;
}
