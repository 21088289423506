import { color, important } from 'csx';
import { classes, style } from 'typestyle';

const transition = 'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out';

const light = color('#f8f9fa');
const notSoLight = light.darken(0.03);

export const bgWhiteHover = style({
	$debugName: 'bgWhiteHover',
	backgroundColor: '#fff',
	transition,
	$nest: {
		'&:hover': {
			backgroundColor: light.toString(),
		},
	},
});

export const bgNotSoLight = style({
	$debugName: 'bgNotSoLight',
	backgroundColor: important(notSoLight.toString()),
});

export const bgNotSoLightHover = style({
	$debugName: 'bgNotSoLightHover',
	backgroundColor: important(notSoLight.toString()),
	transition,
	$nest: {
		'&:hover': {
			backgroundColor: important(notSoLight.darken(0.05).toString()),
		},
	},
});

export const btnNotSoLight = classes(bgNotSoLightHover, 'text-secondary text-decoration-none');
