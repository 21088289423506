import _ from 'lodash';
import React, { ContextType, createContext, FC, useContext, useLayoutEffect, useState } from 'react';
import { apiCall } from './apiCall';

type LoginData = {
	user: RecursivePartial<User.data>;
	permission: RecursivePartial<User.permission>;
};

const LoginContext = createContext<LoginData | null>(null);
LoginContext.displayName = 'LoginContext';

export const useUserdata = () => _.thru(useContext(LoginContext), data => data && data.user)!;
export const useUserPermissions = () => {
	// if (process.env.NODE_ENV === 'development')
	// 	return new Proxy(
	// 		{},
	// 		{
	// 			get() {
	// 				return true;
	// 			},
	// 		}
	// 	) as RecursivePartial<User.permission>;
	return _.thru(useContext(LoginContext), data => data && data.permission)!;
};

export const LoginHandler: FC = ({ children }) => {
	const [data, setData] = useState<ContextType<typeof LoginContext>>(null);

	useLayoutEffect(() => {
		apiCall<undefined, User.response>('GET', '/api/read-session/').then(res => {
			if (res && res.success && res.admin_data && res.permission_data) {
				setData({ user: res.admin_data, permission: res.permission_data });
			} else {
				const url = new URL(process.env.BASE_PATH + '/login', location.origin);
				const redirect = new URLSearchParams({ redirect: window.location.pathname });
				url.search = redirect.toString();
				window.location.href = url.toString();
			}
		});
	}, []);

	return <LoginContext.Provider value={data}>{data && children}</LoginContext.Provider>;
};
