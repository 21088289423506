import React, { FC, useLayoutEffect } from 'react';
import * as BS from 'reactstrap';
import { useDebouncedState } from './useDebouncedState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';

export const Loading: FC<{ debounce?: boolean; show?: boolean; text?: string }> = props => {
	const [show, setShow, instantSetShow] = useDebouncedState<typeof props.show>(void 7, 1000, { leading: true });

	useLayoutEffect(() => {
		if (show != props.show) {
			(props.debounce ? setShow : instantSetShow)(props.show);
		}
	}, [props.show]);

	return (
		<BS.Modal centered isOpen={show} fade={false} size='sm'>
			<BS.ModalBody className='text-center'>
				{/* <FontAwesomeIcon pulse fixedWidth icon={faSpinner} /> */}
				<div className='spinner-border spinner-border-sm text-primary' /> <span className="text-secondary">{props.text || 'Loading...'}</span>
			</BS.ModalBody>
		</BS.Modal>
	);
};
