import React, { useState } from 'react';
import _ from 'lodash';

export const useDebouncedState = <S extends any>(
	value: S | (() => S),
	debounceTime: number,
	options?: { leading?: boolean; trailing?: boolean }
): [
	S,
	React.Dispatch<React.SetStateAction<S>> & _.Cancelable,
	React.Dispatch<React.SetStateAction<S>>
] => {
	const [get, instantSet] = useState(value);

	const [debouncedSet] = useState(() =>
		_.debounce(instantSet, debounceTime, options)
	);

	return [get, debouncedSet, instantSet];
};
