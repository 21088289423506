import { useRouteMatch } from "react-router";

interface Path {
	(template?: TemplateStringsArray, ...args: any[]): string;
}

export const useNestedPath = () => {
	const match = useRouteMatch('');
	const path = match?.path ?? '';

	const Path: Path = function Path(template, ...args) {
		return path + (template?.reduce((memo, v, i) => memo + v + (args[i] ?? ''), '') ?? '');
	};

	Path.toString = function () {
		return path;
	};

	return Path;
};