import React, { FC, ComponentProps, ReactNode } from 'react';
import { Route } from 'react-router-dom';
import { useUserPermissions } from './LoginContext';
import { Toolbar } from './Toolbar';
import _ from 'lodash';

type permissionString = keyof User.permission;

type Props = { fallback?: ReactNode } & (
	| { permission: permissionString } 
	| { every: (permissionString)[] } 
	| { some: (permissionString)[] }
);

export const ProtectedRoute: FC<ComponentProps<typeof Route> & Props> = props => {
	const userPermissions = useUserPermissions();
	const routeProps = _.omit(props, ['permission', 'every', 'some']);
	const fallbackRouteProps = _.omit(routeProps, ['children', 'render', 'component']);

	const fallback = props.fallback ? (
		<Route {...fallbackRouteProps}>{props.fallback}</Route>
	) : (
		<Route {...fallbackRouteProps}>
			<Toolbar title='Access Denied' />
		</Route>
	);

	if ('every' in props && props.every != null) {
		if (props.every.length > 0 && _.every(props.every, perm => userPermissions[perm] === true) === false) {
			return fallback;
		}
	} else if ('some' in props && props.some != null) {
		if (props.some.length > 0 && _.some(props.some, perm => userPermissions[perm] === true) === false) {
			return fallback;
		}
	} else if ('permission' in props && props.permission != null) {
		if (userPermissions[props.permission] !== true) return fallback;
	}

	return <Route {...routeProps} />;
};
