import React, { FC, ReactNode } from 'react';
import { classes } from 'typestyle';
import { bgNotSoLight } from './colors';

export const SectionHeader: FC<{ title?: ReactNode; className?: string; }> = props => (
	<div className={classes('d-flex py-1 mb-3 rounded bg-primary text-white', props.className)}>
		<div className='col'>{props.title || props.children}</div>
	</div>
);

// const roundedStyle = style({ borderRadius: '0.25rem' });

export const SectionFooter: FC<{
	text?: ReactNode;
	alignRight?: boolean;
	round?: boolean;
	roundStart?: boolean;
	roundEnd?: boolean;
	className?: string;
	color?: string;
	textColor?: string;
}> = ({ text, alignRight, roundStart, roundEnd, round = !(roundStart || roundEnd), children, color, textColor = 'secondary', className }) => (
	<div
		className={classes(
			round && 'rounded',
			roundStart && 'rounded-left',
			roundEnd && 'rounded-right',
			alignRight ? 'justify-content-lg-end pr-lg-4' : 'justify-content-lg-start pl-lg-3',
			color ? `bg-${color}` : bgNotSoLight,
			`text-${textColor}`,
			'justify-content-start d-flex p-2 mb-3 w-100',
			className,
		)}
	>
		{text || children}
	</div>
);

export const SectionBreak: FC = () => <div className='pb-3' />;
