import { useStore } from './DataStore';
import { postHeaders, BaseRes, getHeaders, APIRes } from 'core-components/API_Common';
import _ from 'lodash';

const apiUrls = {
	create: '/api/other-claim/',
	get: '/api/other-claim/',
	getAll: '/api/other-claim/all/',
	delete: '/api/other-claim/claim-delete/',
};

export const generateUrl = {
	forCreate: () => `/other-claims/create/`,
	forEdit: (id: string) => `/other-claims/edit/${id}`,
};

/** @deprecated Use apiCall() instead. */
// /* export */ const useCreate = (x = false) => {
// 	const { data } = x ? { data: {} } : useStore();

// 	const submit = async (innerData: object = data) => {
// 		try {
// 			const res = await fetch(apiUrls.create, {
// 				method: 'POST',
// 				headers: postHeaders,
// 				body: JSON.stringify(innerData),
// 			});

// 			if (_.inRange(res.status, 200, 300) == false)
// 				throw new Error(`Create Submit failed, HTTP Code: ${res.status}, Text: ${res.statusText}`);

// 			const json: BaseRes<{ id: string }> = await res.json();

// 			if (json.success == false) {
// 				throw new Error(`Create Submit failed, Message: ${json.messsage != null ? json.messsage : 'Unknown Error'}`);
// 			}

// 			return json;
// 		} catch (e) {
// 			console.error('Error in useCreate - submit', e);
// 			return null;
// 		}
// 	};

// 	return { submit };
// };

/** @deprecated Use apiCall() instead. */
// /* export */ const useUpdate = (claim_id: string, x = false) => {
// 	const { data } = x ? { data: {} } : useStore();

// 	const submit = async (innerData: object = data) => {
// 		try {
// 			const res = await fetch(apiUrls.create, {
// 				method: 'PUT',
// 				headers: postHeaders,
// 				body: JSON.stringify({ claim_id, ...innerData }),
// 			});

// 			if (_.inRange(res.status, 200, 300) == false)
// 				throw new Error(`Create Submit failed, HTTP Code: ${res.status}, Text: ${res.statusText}`);

// 			const json: BaseRes = await res.json();

// 			if (json.success == false) {
// 				throw new Error(json.messsage != null ? json.messsage : 'Unknown Error');
// 			}

// 			return json;
// 		} catch (e) {
// 			console.error('Error in useCreate - submit', e);
// 			return null;
// 		}
// 	};

// 	return { submit };
// };

/** @deprecated Use apiCall() instead. */
export const getAll = async (): APIRes<OtherClaims.dataTypeExtended[]> => {
	try {
		const res = await fetch(process.env.BASE_PATH + apiUrls.getAll, {
			method: 'GET',
			headers: getHeaders,
		});

		if (res.ok) return await res.json();
		if (res.status == 403) window.location.href = '/logout';
	} catch (e) {
		console.error('Error in getAll', e);
	}
	return null;
};

/** @deprecated Use apiCall() instead. */
// /* export */ const getById = async (claim_id: string): APIRes<OtherClaims.dataTypeExtended> => {
// 	try {
// 		const url = new URL(apiUrls.get, location.origin + process.env.BASE_PATH);
// 		url.search = new URLSearchParams({ claim_id }).toString();

// 		const res = await fetch(url.toString(), {
// 			method: 'GET',
// 			headers: getHeaders,
// 		});

// 		if (res.ok) return await res.json();
// 		if (res.status == 403) window.location.href = '/logout';
// 	} catch (e) {
// 		console.error('Error in getAll', e);
// 	}
// 	return null;
// };

/** @deprecated Use apiCall() instead. */
export const searchByItem = async (claim_item: string): APIRes<OtherClaims.dataTypeExtended[]> => {
	try {
		const url = new URL(process.env.BASE_PATH + apiUrls.getAll, location.origin);
		url.search = new URLSearchParams({ claim_id: claim_item }).toString();

		const res = await fetch(url.toString(), {
			method: 'GET',
			headers: getHeaders,
		});

		if (res.ok) return await res.json();
		if (res.status == 403) window.location.href = '/logout';
	} catch (e) {
		console.error('Error in searchByItem', e);
	}
	return null;
};

/** @deprecated Use apiCall() instead. */
export const deleteItems = async (claim_id: string[]) => {
	try {
		const res = await fetch(process.env.BASE_PATH + apiUrls.delete, {
			method: 'POST',
			headers: postHeaders,
			body: JSON.stringify({ claim_id }),
		});

		if (_.inRange(res.status, 200, 300) == false)
			throw new Error(`Create Submit failed, HTTP Code: ${res.status}, Text: ${res.statusText}`);

		const json: BaseRes = await res.json();

		if (json.success == false) {
			throw new Error(json.messsage != null ? json.messsage : 'Unknown Error');
		}

		return json;
	} catch (e) {
		console.error('deleteItems', e);
	}
	return null;
};
