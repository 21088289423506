import 'bootstrap-datepicker';
import $ from 'jquery';
import moment from "moment";
import React, { ComponentProps, ComponentType, useLayoutEffect, useRef } from "react";
import { FormFeedback, Input } from "reactstrap";
import { createStore } from "./CreateStore";
import { Outside, Wrapper } from "./Styles";
import { useTextInputState } from "./TextInput";

type Props = ComponentProps<typeof Input> & {
	inputGroupClassName?: string;
	floatingError?: boolean;
};

export const DatepickerStore = createStore(useDatepickerState);

export const Datepicker: ComponentType<Props> = ({ inputGroupClassName, floatingError, ...inputProps }) => {
	const state = DatepickerStore.useContext();

	return (
		<>
			<Wrapper>
				<Input innerRef={state.ref} disabled={state.disabled} invalid={state.touched && state.errors.length > 0} {...inputProps} />
				<Outside active={floatingError}>
					{state.touched &&
						state.errors.map((m, k) => (
							<FormFeedback key={k} className='d-block'>
								{m}
							</FormFeedback>
						))}
					{(state.touched == false || state.errors.length < 1) && <FormFeedback className='d-block'>&nbsp;</FormFeedback>}
				</Outside>
				{floatingError || <div className='mb-2' />
				}
			</Wrapper>
		</>
	);
};

function useDatepickerState(setup?: (state: ReturnType<typeof useTextInputState>) => void) {
	const inputState = useTextInputState(setup);
	const ref = useRef<HTMLInputElement>(null);

	useLayoutEffect(() => {
		if (ref.current) {
			$(ref.current)
				.datepicker({
					format: 'dd/mm/yyyy',
				})
				.on('changeDate', ({ date }) => {
					inputState.dispatch.setValue(moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY'));
					inputState.dispatch.setTouched(true);
				})
				.on('hide', () => {
					inputState.dispatch.setTouched(true);
				})
				.on('clearDate', () => {
					inputState.dispatch.setValue('');
				});

			if (inputState.value) {
				$(ref.current).datepicker('setDate', moment(inputState.value, 'DD/MM/YYYY').toDate());
			}
		}

		return () => {
			ref.current && $(ref.current).datepicker('destroy');
		};
	}, []);

	useLayoutEffect(() => {
		if (ref.current) {
			if (inputState.value) {
				$(ref.current).datepicker('setDate', moment(inputState.value, 'DD/MM/YYYY').toDate());
			}
		}
	}, [inputState.value]);


	const state = { ...inputState, ref };

	return state as Readonly<typeof state>;
}