import React, { ComponentProps, FC, ReactNode } from 'react';
import * as BS from 'reactstrap';

type props = {
	message?: ReactNode;
	show?: boolean;

	noLabel?: ReactNode;
	noColor?: ComponentProps<typeof BS.Button>['color'];
	onCancel: () => void;

	yesLabel?: ReactNode;
	yesColor?: ComponentProps<typeof BS.Button>['color'];
	onConfirm: () => void;

	onClosed?: () => void;
};

export const Prompt: FC<props> = props => {
	return (
		<BS.Modal centered isOpen={props.show} toggle={props.onCancel} onClosed={props.onClosed} fade={false}>
			<BS.ModalBody>
				<div className='pt-2 pb-3'>{props.children || props.message}</div>
				<div className='text-right'>
					<BS.Button color={props.noColor || 'not-so-light'} onClick={props.onCancel}>
						{props.noLabel || 'No'}
					</BS.Button>
					<span className='px-2' />
					<BS.Button color={props.yesColor} onClick={props.onConfirm}>
						{props.yesLabel || 'Yes'}
					</BS.Button>
				</div>
			</BS.ModalBody>
		</BS.Modal>
	);
};
