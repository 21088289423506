import React, { FC, MouseEventHandler, ReactNode } from 'react';
import { Col, ListGroupItem, Row } from 'reactstrap';
import { classes } from 'typestyle';
import { bgNotSoLightHover, bgWhiteHover } from './colors';

type props = {
	className?: string;
	canSelect?: boolean;
	isSelected?: boolean;
	buttonSlot?: ReactNode;
	onSelect?: MouseEventHandler;
};

export const ListingItem: FC<props> = props => (
	<ListGroupItem
		className={classes(props.canSelect && 'cursor-pointer', props.canSelect && (props.isSelected ? bgNotSoLightHover : bgWhiteHover), props.className)}
		onClick={props.canSelect ? props.onSelect : void 7}
	>
		<Row className='d-flex align-items-center'>
			<Col xs='8' md='10'>
				{props.children}
			</Col>
			<Col xs='4' md='2' className='text-right float-right'>
				{props.buttonSlot}
			</Col>
		</Row>
	</ListGroupItem>
);
