import React, { ComponentType } from "react";
import styled from "styled-components";
import { makeGrid, placeAt } from "util/GridUtils.sc";
import { useUserdata } from "./LoginContext";
import { useShowingFullWidthContent } from "./Navbar";

const logo = require('images/logo_header_02.svg');
const bgCross = require('images/denso-cross.png');

export const WelcomePage: ComponentType = () => {
	const userdata = useUserdata();

	useShowingFullWidthContent();

	const name = [userdata.first_name, userdata.last_name].filter(x => x).join(' ');
	return (
		<Wrapper>
			<Logo />
			<TextWrapper>
				<h5>Welcome {name},</h5>

				<h5>You are now logged in to</h5>

				<h5>DENSO Service Integrated System.</h5>
			</TextWrapper>
			<Cross />
		</Wrapper>
	);
};

const Wrapper = styled.div.attrs(() => ({ className: 'mt-n2 mr-n3' }))`
	height: 100%;
	${makeGrid({ cols: 'minmax(180px, auto) minmax(auto, 300px)', rows: '1fr auto 1fr' })}
`;

const Logo = styled.img.attrs(() => ({ className: 'my-5 mr-5 ml-3', src: logo }))`
	${placeAt({ col: 1, row: 1 })}
	width: 200px;
`;

const TextWrapper = styled.div.attrs(() => ({ className: 'ml-3' }))`
	${placeAt({ col: 1, row: 2 })}
`;

const Cross = styled.div`
	${placeAt({ col: 2, row: 1, rowSpan: 3 })}
	background-image: url(${bgCross});
	background-size: 60vh;
	background-repeat: no-repeat;
	background-position-x: left;
`;