import _ from 'lodash';

export const getDebounce = () => _.debounce((callback: CallableFunction) => callback(), 1000, { leading: true });

export const getAsyncDebounce = () => {
	const debounce = getDebounce();
	return <T extends any>(callback?: () => T) => {
		return new Promise<T>(done => {
			return debounce(() => done(callback?.()));
		});
	};
};
