import { css, FlattenSimpleInterpolation } from 'styled-components';
import { useState, useLayoutEffect } from 'react';

export const mdDown = (style: FlattenSimpleInterpolation | string) => css`
	@media screen and (max-width: 991.98px) { 
		${style}
	}
`;
export const lgUp = (style: FlattenSimpleInterpolation | string) => css`
	@media screen and (min-width: 992px) { 
		${style}
	}
`;

export const useMinWidth = (px: number) => {
	const [isLgUp, setLgUp] = useState(window.innerWidth >= px);

	useLayoutEffect(() => {
		function resize() {
			const willLgUp = window.innerWidth >= 992;
			if (willLgUp != isLgUp) {
				setLgUp(willLgUp);
			}
		}
		window.addEventListener('resize', resize);

		return () => window.removeEventListener('resize', resize);
	}, []);

	return isLgUp;
};