import { yRequired, yRequiredSelect, yNumber, yDecimal, yDate } from 'core-components/validation_common';
import { xInputState, xInputSetup, xDatepickerState } from 'core-components/X/XInputs';
import { xSelectState, xSelectSetup } from 'core-components/X/XSelects';

export class optionalText extends xInputState {}

export class requiredText extends xInputState {
	constructor(setup?: xInputSetup) {
		super({ schema: yRequired });
		super(setup);
	}
}

export class disabledText extends xInputState {
	constructor(setup?: xInputSetup) {
		super({ $disabled: true });
		super(setup);
	}
}

export class optionalNumber extends xInputState {
	constructor(setup?: xInputSetup) {
		super({ schema: yNumber });
		super(setup);
	}
}

export class optionalDate extends xDatepickerState {
	constructor(setup?: xInputSetup) {
		super({ schema: yDate });
		super(setup);
	}
}

export class requiredNumber extends xInputState {
	constructor(setup?: xInputSetup) {
		super({ schema: yRequired.concat(yNumber) });
		super(setup);
	}
}

export class requiredDecimal extends xInputState {
	constructor(setup?: xInputSetup) {
		super({ schema: yRequired.concat(yDecimal) });
		super(setup);
	}
}

export class requiredSelect extends xSelectState {
	constructor(setup?: xSelectSetup) {
		super({ schema: yRequiredSelect });
		super(setup);
	}
}
