import React, { ComponentProps, FC, ReactNode, useMemo, useState } from 'react';
import { PageBtns } from './PageBtns';

export const usePagination = (numberOfItems: number, itemsPerPage: number, initialPage?: number, enableGoto?: boolean) => {
	const [pageIndex, setPageIndex] = useState(initialPage || 0);

	const lastPageIndex = useMemo(() => (Math.max(0, numberOfItems == null ? 0 : Math.ceil(numberOfItems / itemsPerPage) - 1)), [numberOfItems]);

	const prev = () => setPageIndex(p => Math.max(0, p - 1));
	const next = () => setPageIndex(p => Math.min(lastPageIndex, p + 1));
	const goto = (p: number) => {
		const pInt = Math.floor(p);
		const pIdx = pInt - 1;
		const pMin = Math.max(0, pIdx);
		const pMinMax = Math.min(lastPageIndex, pMin);
		setPageIndex(isNaN(pMinMax) ? 0 : pMinMax);
	}

	/** @deprecated Use paginationRender() instead */
	const DEPRECATED_Pagination: FC<{ bigSlot?: ComponentProps<typeof PageBtns>['bigSlot']; }> = ({ bigSlot }) => (
		<PageBtns {...{ prev, next, pageIndex, lastPageIndex, bigSlot }} />
	);

	const paginationRender = ({ bigSlot }: { bigSlot?: ReactNode; } = {}) => <PageBtns {...{ prev, next, pageIndex, lastPageIndex, bigSlot, goto, enableGoto }} />;

	const reset = () => setPageIndex(0);

	return {
		DEPRECATED_Pagination,
		reset,
		pageIndex,
		lastPageIndex,
		paginationRender
	};
};
