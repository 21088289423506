import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ComponentProps, FC, useLayoutEffect, useState } from 'react';
import { Button, Input, InputProps } from 'reactstrap';
import styled, { css } from 'styled-components';
import { safeParseInt } from 'util/safeParseNumber';
import { Toolbar } from './Toolbar';

const LightenLinkButton = styled(Button).attrs(() => ({ color: 'link', className: 'text-secondary' }))`
	${({ disabled }) => disabled && css`
		opacity: 0.3 !important;
	`}
`;

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	user-select: none;
`;

type Props = {
	rowClassName?: string;
	bigSlot?: ComponentProps<typeof Toolbar>['bigSlot'];
	prev: () => void;
	next: () => void;
	pageIndex: number;
	lastPageIndex: number;
	itemName?: string;
	enableGoto?: boolean;
	goto?: (p: number) => void;
};

const GotoInput = styled(Input)`
	display: inline-block;
	width: 60px;
	text-align: center;
` as any as typeof Input;

export const PageBtns: FC<Props> = ({ bigSlot, prev, next, pageIndex, lastPageIndex, rowClassName, itemName = 'Page', goto, enableGoto }) => {
	const [internalPage, setInternalPage] = useState(String(pageIndex + 1));

	useLayoutEffect(() => {
		setInternalPage(String(pageIndex + 1));
	}, [pageIndex])

	return (
		<Toolbar
			rowClassName={rowClassName}
			bigSlot={bigSlot}
			leftSlot={
				<Wrapper>
					<LightenLinkButton onClick={prev} disabled={pageIndex == 0}>
						<FontAwesomeIcon fixedWidth size='lg' icon={faAngleLeft} />
					</LightenLinkButton>
					{enableGoto
						? (
							<span>
								{itemName}{" "}
								<GotoInput
									className="mx-1"
									value={internalPage}
									onChange={e => setInternalPage(e.target.value)}
									onKeyDown={e => {
										if (e.key === "Enter") {
											goto?.(safeParseInt(internalPage));
											e.currentTarget.blur();
										}
									}}
									onBlur={() => setInternalPage(String(pageIndex + 1))}
								/>
								{" "}of {lastPageIndex + 1}
							</span>
						) : (
							<span className='px-3'>
								{itemName} {pageIndex + 1} of {lastPageIndex + 1}
							</span>
						)}
					<LightenLinkButton onClick={next} disabled={pageIndex == lastPageIndex}>
						<FontAwesomeIcon fixedWidth size='lg' icon={faAngleRight} />
					</LightenLinkButton>
				</Wrapper>
			} />
	);
};
