import React, { ComponentType, ReactNode } from "react";
import { When } from "react-if";
import styled from "styled-components";

export const UIInfoCell: ComponentType<{ className?: string; label?: ReactNode; }> = props => (
	<div className={props.className}>
		<When condition={props.label != null}>
			<FadedSmall style={{ opacity: 0.6 }}>{props.label}</FadedSmall><br />
		</When>
		{props.children}
	</div>
);

const FadedSmall = styled.small`
	opacity: 0.6
`;