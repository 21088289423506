import { apiCall } from 'core-components/apiCall';
import { Loading } from 'core-components/Loading';
import { LoginHandler } from 'core-components/LoginContext';
import { Navbar } from 'core-components/Navbar';
import { withNotification } from 'core-components/Notification';
import { Toolbar } from 'core-components/Toolbar';
import { WelcomePage } from 'core-components/WelcomePage';
import React, { ComponentType, FC, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { lazyImport } from 'util/lazyImport';

const LoginPage = lazyImport(() => import('./core_components/LoginPage.lazy'), m => m.LoginPage);
const BusController = lazyImport(() => import('./bus/BusController.lazy'), m => m.BusController);
const FTRController = lazyImport(() => import('./ftr/FTRController.lazy'), m => m.FTRController);
const WCController = lazyImport(() => import('./wc/WCController.lazy'), m => m.WCController);
const LabourController = lazyImport(() => import('./labour/LabourController.lazy'), m => m.LabourController);
const PubController = lazyImport(() => import('./publication/PubController'), m => m.PubController);
const OCController = lazyImport(() => import('other-claims/OCController'), m => m.OCController);
const UsersController = lazyImport(() => import('users/UsersController'), m => m.UsersController);
const UsageController = lazyImport(() => import('./usage/UsageController'), m => m.UsageController);
const RepController = lazyImport(() => import('./report/RepController'), m => m.RepController);

export const Controller: FC = () => (
	<BrowserRouter basename={process.env.BASE_PATH}>
		<Render />
	</BrowserRouter>
);

export const Render: ComponentType = withNotification(({ Notification }) => (
	<Suspense fallback={null}>
		<Switch>
			<Route path='/login'>
				<LoginPage />
			</Route>

			<Route
				path='/logout'
				render={({ history }) => {
					apiCall('POST', '/api/user/logout').then(() => {
						history.replace(process.env.BASE_PATH + '/login' + window.location.hash);
					});
					return null;
				}}
			/>

			<LoginHandler>
				<Route>
					<Navbar>
						<Notification />
						<Suspense fallback={<Loading show />}>
							<Switch>
								<Route exact path='/'>
									<WelcomePage />
								</Route>

								<Route path='/bus'>
									<BusController />
								</Route>

								<Route path='/ftr'>
									<FTRController />
								</Route>

								<Route path='/oper-code' >
									<LabourController />
								</Route>

								<Route path='/other-claims'>
									<OCController />
								</Route>

								<Route path='(/wc|/warranty-claim)'>
									<WCController />
								</Route>

								<Route path='/users'>
									<UsersController />
								</Route>

								<Route path='/usage'>
									<UsageController />
								</Route>

								<Route path='/publication' >
									<PubController />
								</Route>

								<Route path='/report'>
									<RepController />
								</Route>

								<Route>
									<Toolbar title='Not Found' />
								</Route>
							</Switch>
						</Suspense>
					</Navbar>
				</Route>
			</LoginHandler>
		</Switch>
	</Suspense>
));
