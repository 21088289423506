import React, { createContext, PropsWithChildren, useContext } from "react";

interface Store<S extends any, I extends any> {
	(props: PropsWithChildren<{ initialValue?: I; }>): JSX.Element;
	useContext: () => S;
	useStore: (initialValue?: I) => { state: S, connect: (render: JSX.Element) => JSX.Element; };
	context: React.Context<S | undefined>;
}

export function createStore<S extends any, I extends any>(useHook: (initialValue: I | undefined) => S, storeName?: string) {
	const context = createContext<S | undefined>(void 7);
	context.displayName = storeName;

	const Store: Store<S, I> = (({ initialValue, children }) => {
		const state = useHook(initialValue);
		return (
			<context.Provider value={state}>
				{children}
			</context.Provider>
		);
	}) as Store<S, I>;

	Store.useContext = () => {
		const state = useContext(context);
		if (state == null) throw new Error('Component needs to be wrapped in Provider!');
		return state;
	};

	Store.useStore = initialValue => {
		const state = useHook(initialValue);

		const connect = (render: JSX.Element) => (
			<context.Provider value={state}>
				{render}
			</context.Provider>
		);
		return { state, connect };
	};

	Store.context = context;

	return Store;
}