import { APIRes, getHeaders, postHeaders } from 'core-components/API_Common';

const URLs = {
	getAll: '/api/ftr/all/',
	upload: '/api/ftr/upload/',
	delete: '/api/ftr/ftr-delete/',
};

export const generateFtrURL = {
	forQuery: (ftr_no: string) => `/ftr/query/#${new URLSearchParams({ ftr_no }).toString()}`,
	forEdit: (ftr_id: string) => `/ftr/edit/${ftr_id}/`,
	forView: (ftr_id: string) => `/ftr/view/${ftr_id}/`,
};

export const getAllFtr = async (): APIRes<FtrInfo[]> => {
	try {
		const url = new URL(location.origin + process.env.BASE_PATH + URLs.getAll);

		const res = await fetch(url.toString(), {
			method: 'GET',
			headers: getHeaders,
		});

		if (res.ok) {
			return await res.json();
		}
		if (res.status == 403) window.location.href = '/logout';
	} catch (e) {
		console.error('getAllFtr Error: ', e);
	}
	return null;
};

export const deleteFtrs = async (ftr_id: string[]) => {
	try {
		const url = new URL(location.origin + process.env.BASE_PATH + URLs.delete);

		const res = await fetch(url.toString(), {
			method: 'POST',
			headers: postHeaders,
			body: JSON.stringify({ ftr_id }),
		});

		if (res.ok) {
			return await res.json();
		}
		if (res.status == 403) window.location.href = '/logout';
	} catch (e) {
		console.error('deleteFtrs Error', e);
	}

	return null;
};
