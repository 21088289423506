import { values } from "lodash";
import React, { ComponentProps, ComponentType, ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { FormFeedback, Input, InputGroup } from "reactstrap";
import * as Yup from 'yup';
import { createStore } from "./CreateStore";
import { Outside, Wrapper } from "./Styles";

type TextInputState = ReturnType<typeof useTextInputState>;

type Props = ComponentProps<typeof Input> & {
	inputGroupClassName?: string;
	prepend?: ReactNode;
	append?: ReactNode;
	floatingError?: boolean;
};

export const TextInputStore = createStore(useTextInputState);

export const TextInput: ComponentType<Props> = ({ inputGroupClassName, prepend, append, floatingError, ...inputProps }) => {
	const state = TextInputStore.useContext();

	return (
		<>
			<Wrapper fullHeight={inputProps.type === 'textarea'}>
				<InputGroup className={inputGroupClassName}>
					{prepend}
					<Input
						{...inputProps}
						disabled={inputProps.disabled || state.disabled}
						invalid={inputProps.invalid || (state.touched && state.errors.length > 0)}
						value={inputProps.value ?? state.value}
						onBlur={e => {
							inputProps.onBlur?.(e);
							state.dispatch.setTouched(true);
						}}
						onChange={e => {
							inputProps.onChange?.(e);
							state.dispatch.setValue(e.currentTarget.value);
							state.dispatch.setTouched(true);
						}}
						onKeyDown={e => {
							inputProps.onKeyDown?.(e);
							if (e.key === 'Enter') {
								e.preventDefault();
								e.currentTarget.blur();
							}
						}}
					/>
					{append}
				</InputGroup>
				<Outside active={floatingError}>
					{state.touched &&
						state.errors.map((m, k) => (
							<FormFeedback key={k} className='d-block'>
								{m}
							</FormFeedback>
						))}
					{(state.touched == false || state.errors.length < 1) && <FormFeedback className='d-block'>&nbsp;</FormFeedback>}
				</Outside>
				{floatingError || <div className='mb-2' />}
			</Wrapper>
		</>
	);
};


export const UncontrolledTextInput: ComponentType<Props & {
	setup?: Parameters<typeof useTextInputState>[0];
	onStateChange?: (state: TextInputState) => void;
	deps?: (state: TextInputState) => any[];
}> = ({ setup, onStateChange, deps, ...props }) => {
	const { state, connect } = TextInputStore.useStore(setup);
	const { dispatch, ...stateValues } = state;

	useEffect(() => {
		onStateChange?.(state);
	}, deps?.(state) ?? values(stateValues));

	return connect(<TextInput {...props} />);
};

export function useTextInputState(setup?: (state: TextInputState) => void) {
	const [value, setValue] = useState<string>('');
	const [errors, setErrors] = useState<string[]>([]);
	const [touched, setTouched] = useState<boolean>(false);

	const [disabled, setDisabled] = useState<boolean>(false);
	const [schema, setSchema] = useState<Yup.StringSchema<string>>(() => Yup.string());

	const dispatch = {
		setValue, setErrors,
		setTouched, setDisabled, setSchema,
	};

	useLayoutEffect(() => {
		try {
			schema.validateSync(value);
			setErrors([]);
		}
		catch (e) {
			const validation: Yup.ValidationError = e;
			setErrors(validation.errors);
		}
	}, [value, schema]);

	const state = {
		dispatch: dispatch as Readonly<typeof dispatch>,
		value, errors,
		touched, disabled, schema,
		valid: errors.length < 1,
		invalid: errors.length > 0,
	};

	useLayoutEffect(() => {
		setup && setup(state);
	}, []);

	return state as Readonly<typeof state>;
}
