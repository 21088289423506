import { autocompleteOption, XAutocompleteHandler } from 'core-components/X/XAutocomplete';
import { uniqueId, values } from "lodash";
import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { AsyncTypeahead, AsyncTypeaheadProps } from "react-bootstrap-typeahead";
import { FormFeedback } from "reactstrap";
import { createStore } from "./CreateStore";
import { Outside, Wrapper } from "./Styles";
import { useTextInputState } from './TextInput';

export const TypeaheadInputStore = createStore(useTypeaheadInputState);

export type TypeaheadInputHandler = XAutocompleteHandler;

type Props = Partial<AsyncTypeaheadProps<autocompleteOption>> & { floatingError?: boolean; };

export const TypeaheadInput = forwardRef<TypeaheadInputHandler, Props>(({ floatingError, ...inputProps }, ref) => {
	const typeRef = useRef<any>();
	const state = TypeaheadInputStore.useContext();

	useImperativeHandle(ref, () => ({
		blur() {
			typeRef.current.getInstance().blur();
		},
		focus() {
			typeRef.current.getInstance().focus();
		},
		clear() {
			state.dispatch.setSelectedOption(null);
			state.dispatch.setValue('');
			typeRef.current.getInstance().clear();
		},
		get input() {
			return typeRef.current.getInstance().getInput();
		},
	}));

	const [tempId] = useState(uniqueId);

	return (
		<>
			<Wrapper>
				<AsyncTypeahead<autocompleteOption>
					{...inputProps}
					ref={ins => (typeRef.current = ins)}
					id={tempId}
					options={state.options}
					disabled={inputProps.disabled || state.disabled}
					isInvalid={inputProps.isInvalid || (state.touched && state.errors.length > 0)}
					isLoading={state.loading}
					selected={state.selectedOption ? [state.selectedOption] : []}
					onSearch={async query => {
						state.dispatch.setLoading(true);
						state.dispatch.setOptions(await state.onQuery(query));
						state.dispatch.setLoading(false);
					}}
					onBlur={e => {
						inputProps.onBlur && inputProps.onBlur(e);
						if (state.selectedOption == null) {
							state.dispatch.setValue('');
							typeRef.current.getInstance().clear();
						}
						state.dispatch.setTouched(true);
					}}
					onInputChange={(i, e) => {
						inputProps.onInputChange && inputProps.onInputChange(i, e);
						state.dispatch.setTouched(true);
					}}
					onChange={e => {
						inputProps.onChange && inputProps.onChange(e);
						state.dispatch.setTouched(true);
						state.dispatch.setValue(e[0] ? e[0].label : '');
						state.dispatch.setSelectedOption(e[0]);
					}}
				/>
				<Outside active={floatingError}>
					{state.touched &&
						state.errors.map((m, k) => (
							<FormFeedback key={k} className='d-block'>
								{m}
							</FormFeedback>
						))}
					{(!state.touched || state.errors.length < 1) && <FormFeedback className='d-block'>&nbsp;</FormFeedback>}
				</Outside>
				{floatingError || <div className='mb-2' />}
			</Wrapper>
		</>
	);
});

export const UncontrolledTypeaheadInput = forwardRef<TypeaheadInputHandler, Props & {
	setup?: Parameters<typeof useTypeaheadInputState>[0];
	onStateChange?: (state: TypeaheadInputState) => void;
	deps?: (state: TypeaheadInputState) => any[];
}>(({ setup, onStateChange, deps, ...props }, ref) => {
	const { state, connect } = TypeaheadInputStore.useStore(setup);
	const { dispatch, ...stateValues } = state;

	useEffect(() => {
		onStateChange?.(state);
	}, deps?.(state) ?? values(stateValues));

	return connect(<TypeaheadInput ref={ref} {...props} />);
});

export type TypeaheadInputState = ReturnType<typeof useTypeaheadInputState>;

export function useTypeaheadInputState(setup?: (state: TypeaheadInputState) => void) {
	const inputState = useTextInputState();

	const [options, setOptions] = useState<autocompleteOption[]>([]);
	const [loading, setLoading] = useState(false);
	const [selectedOption, setSelectedOption] = useState<autocompleteOption | null>(null);
	const [onQuery, setOnQuery] = useState<(query: string) => Promise<autocompleteOption[]>>(async () => []);

	const dispatch = {
		...inputState.dispatch,
		setOptions,
		setLoading,
		setSelectedOption,
		setOnQuery,
	};

	const state = {
		...inputState,
		dispatch: dispatch as Readonly<typeof dispatch>,
		options,
		loading,
		selectedOption,
		onQuery,
	};

	useLayoutEffect(() => {
		setup && setup(state);
	}, []);

	return state as Readonly<typeof state>;
};