import _ from 'lodash';
import React, { ComponentType, createContext, FC, PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import { Alert } from 'reactstrap';
import styled from 'styled-components';
import { useContextOrError } from 'util/useContextOrError';

const NotificationContext = createContext<ReturnType<typeof useSetup>['push'] | null>(null);
NotificationContext.displayName = 'NotificationContext';

const NotifWrapper = styled.div`
	position: fixed;
	top: 1rem;
	right: 1rem;
	z-index: 9999;
`;

const NotifAlert = styled(Alert)`
	min-width: 300px;
`;

const useSetup = () => {
	const [notifProps, setNotifProps] = useState<(NotificationProps & { giveKey: number; })[]>([]);
	const notifKey = useRef(1);

	const push = (content: ReactNode, color?: string) => {
		setNotifProps(np => np.concat({ content, color, giveKey: notifKey.current++ }));
		if (notifKey.current == 100) notifKey.current = 1;
	};

	const remove = (removeKey: number) => {
		setNotifProps(notifs => _.filter(notifs, n => n.giveKey !== removeKey));
	};

	const elem = () => {
		return (
			<NotifWrapper>
				{_(notifProps)
					.map(props => <Notification key={props.giveKey} {...props} onClose={() => remove(props.giveKey)} />)
					.value()}
			</NotifWrapper>
		);
	};

	return { elem, push };
};

type NotificationProps = {
	content?: ReactNode;
	color?: string;
	onClose?: () => void;
};

const Notification: FC<NotificationProps> = props => {
	const [isOpen, setOpen] = useState(true);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setOpen(false);
			props.onClose?.();
		}, 3500);
		return () => clearTimeout(timeout);
	}, []);

	return (
		<>
			{props.content && (
				<NotifAlert isOpen={isOpen} fade={false} color={props.color}>
					{props.content}
				</NotifAlert>
			)}
		</>
	);
};

export const withNotification = <P extends {} = {}>(
	Component: ComponentType<P & { Notification: typeof Notification; }>
) => (props: PropsWithChildren<P>) => {
	const value = useSetup();

	return (
		<NotificationContext.Provider value={value.push}>
			<Component {...props} Notification={value.elem} />
		</NotificationContext.Provider>
	);
};

export const useNotification = () => ({ push: useContextOrError(NotificationContext) });
