import { faCircle } from '@fortawesome/free-regular-svg-icons/faCircle';
import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons/faDotCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { action, observable, reaction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { ComponentProps, FC, ReactNode } from 'react';
import { FormFeedback, FormGroup, Input, InputGroup, Label } from 'reactstrap';
import { classes } from 'typestyle';
import { useXValidate, xBaseDefaultState, xBaseDefaultStateType, xBaseSetup, xBaseState } from './XBase';

export type optionObject = { label: string; value: string; disabled?: boolean; };
export type optionsArray = optionObject[];

// ===== state (class version) =================================================
// ===== state (class version) =================================================
// ===== state (class version) =================================================

export type xSelectSetup = xBaseSetup & {
	$options?: optionsArray;
	$disabledValues?: string[];
	$selectPlaceholder?: ReactNode;
};

export class xSelectState extends xBaseState {
	@observable $options: optionsArray = [];
	@observable $disabledValues?: string[];

	@observable $selectPlaceholder?: ReactNode;

	constructor(setup?: xSelectSetup) {
		super(setup);
		if (setup) {
			_.assign(this, _.pick(setup, ['$options', '$disabledValues', '$selectPlaceholder']));
		}
	}

	@action $addOptions(...options: optionsArray): this {
		this.$options = this.$options.concat(options);
		return this;
	}
}

export class xCheckboxState extends xSelectState {
	@observable $values = new Set<string>();

	constructor(setup?: xSelectSetup) {
		super(setup);
		if (setup?.value) {
			this.$values = new Set(setup.value.split(","));
		}

		reaction(() => this.$values.size, () => {
			this.value = [...this.$values.values()].join(",");
		});
	}
}

// ===== state (old deprecated version) ========================================
// ===== state (old deprecated version) ========================================
// ===== state (old deprecated version) ========================================

/** @deprecated Use the xSelectState class instead. */
export interface selectState extends xBaseDefaultStateType<selectState> {
	$options: optionsArray;
	$disabledValues?: string[];

	$selectPlaceholder?: ReactNode;
	$addOptions: (...options: optionsArray) => selectState;
}

/** @deprecated Use the xSelectState class instead. */
export const xSelectDefaultInitialState: selectState = {
	...xBaseDefaultState,
	$options: [],
	$addOptions(...options) {
		return { ...this, $options: [...this.$options, ...options] };
	},
};

// ===== component - radio =====================================================
// ===== component - radio =====================================================
// ===== component - radio =====================================================

// const radioDotOverlayStyle = style({
// 	transition: 'opacity 0.15s ease-in-out',
// 	position: 'absolute',
// 	top: 0,
// 	bottom: 0,
// 	left: 0,
// 	margin: 'auto',
// 	opacity: 0,
// 	$nest: {
// 		'&.active': { opacity: 1 },
// 	},
// });

type Props = { stateRef?: selectState | xSelectState; } & ComponentProps<typeof Input>;

export const XRadio: FC<Props> = observer(({ stateRef: current = useLocalStore(() => new xSelectState()), ...inputProps }) => {
	useXValidate(current);

	return (
		<>
			<FormGroup className='d-flex mb-0'>
				{current.$options.map(({ label, value, disabled }) => {
					const isChecked = current.value == value;
					return (
						<InputGroup key={value}>
							<Label className='cursor-pointer position-relative'>
								<FontAwesomeIcon
									fixedWidth
									icon={isChecked ? faDotCircle : faCircle}
									className={classes('mr-2', isChecked ? 'text-primary' : 'text-secondary')}
									style={{ transition: 'color 0.15s ease-in-out' }}
								/>
								{/* <FontAwesomeIcon fixedWidth icon={faDotCircle} className={classes(radioDotOverlayStyle, isChecked && 'active', 'text-primary')} /> */}
								<Input
									className='d-none'
									type='radio'
									value={value}
									checked={isChecked}
									disabled={disabled || (current.$disabledValues && current.$disabledValues.includes(value))}
									onChange={e => e.currentTarget.checked && (current.value = value)}
									{...inputProps}
								/>
								{label}
							</Label>
						</InputGroup>
					);
				})}
			</FormGroup>
			{current.$touched &&
				current.errors.map((m, k) => (
					<FormFeedback key={k} className='d-block'>
						{m}
					</FormFeedback>
				))}
			{(!current.$touched || current.errors.length < 1) && <FormFeedback className='d-block'>&nbsp;</FormFeedback>}
			<div className='mb-2' />
		</>
	);
});

// ===== component - select ===================================================
// ===== component - select ===================================================
// ===== component - select ===================================================

export const XSelect: FC<Props> = observer(({ stateRef: current = useLocalStore(() => new xSelectState()), ...inputProps }) => {
	useXValidate(current);

	return (
		<>
			<InputGroup>
				<Input
					type='select'
					className='custom-select'
					value={current.value}
					disabled={current.$disabled}
					invalid={current.$touched && current.errors.length > 0}
					onBlur={() => (current.$touched = true)}
					onChange={e => {
						current.$touched = true;
						current.value = e.currentTarget.value;
					}}
					{...inputProps}
				>
					<option disabled value=''>
						{current.$selectPlaceholder || 'Please Select'}
					</option>
					{current.$options.map(({ label, value, disabled }) => (
						<option key={value} value={value} disabled={disabled || (current.$disabledValues && current.$disabledValues.includes(value))}>
							{label}
						</option>
					))}
				</Input>
			</InputGroup>
			{current.$touched &&
				current.errors.map((m, k) => (
					<FormFeedback key={k} className='d-block'>
						{m}
					</FormFeedback>
				))}
			{(!current.$touched || current.errors.length < 1) && <FormFeedback className='d-block'>&nbsp;</FormFeedback>}
			<div className='mb-2' />
		</>
	);
});

// ===== component - checkbox ==================================================
// ===== component - checkbox ==================================================
// ===== component - checkbox ==================================================

type CheckboxProps = { stateRef?: xCheckboxState; } & ComponentProps<typeof Input>;

export const XCheckbox: FC<CheckboxProps> = observer(({ stateRef: current = useLocalStore(() => new xCheckboxState()), ...inputProps }) => {
	useXValidate(current);

	return (
		<>
			<FormGroup className='d-flex mb-0'>
				{current.$options.map(({ label, value, disabled }) => {
					const isChecked = current.$values.has(value);

					return (
						<InputGroup key={value}>
							<Label className='cursor-pointer position-relative'>
								<FontAwesomeIcon
									fixedWidth
									icon={isChecked ? faCheckSquare : faSquare}
									className={classes('mr-2', isChecked ? 'text-primary' : 'text-secondary')}
									style={{ transition: 'color 0.15s ease-in-out' }}
								/>
								<Input
									className='d-none'
									type='checkbox'
									value={value}
									checked={isChecked}
									disabled={disabled || (current.$disabledValues && current.$disabledValues.includes(value))}
									onChange={e => e.currentTarget.checked ? (current.$values.add(value)) : current.$values.delete(value)}
									{...inputProps}
								/>
								{label}
							</Label>
						</InputGroup>
					);
				})}
			</FormGroup>
			{current.$touched &&
				current.errors.map((m, k) => (
					<FormFeedback key={k} className='d-block'>
						{m}
					</FormFeedback>
				))}
			{(!current.$touched || current.errors.length < 1) && <FormFeedback className='d-block'>&nbsp;</FormFeedback>}
			<div className='mb-2' />
		</>
	);
});
