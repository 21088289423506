import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faBus } from '@fortawesome/free-solid-svg-icons/faBus';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faFunnelDollar } from '@fortawesome/free-solid-svg-icons/faFunnelDollar';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons/faHandHoldingUsd';
import { faPeopleCarry } from '@fortawesome/free-solid-svg-icons/faPeopleCarry';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faTools } from '@fortawesome/free-solid-svg-icons/fatools';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons/faFileCsv';

export type NavLinks = {
	link: string;
	icon: IconDefinition;
	pattern: RegExp;
	childPattern?: RegExp;
	label: string;
	external?: boolean;
	permission?: keyof User.permission;
	some?: (keyof User.permission)[];
}[];

export const NavLinks: NavLinks = [
	{
		label: 'Field Reports',
		icon: faTools,
		pattern: /^\/ftr\/?$/,
		childPattern: /^\/ftr\/.+/,
		link: '/ftr/',
		some: ['viewlAllFTR', 'viewAllOwnSDFTR', 'viewOwnFTR'],
	},
	{
		label: 'Bus',
		icon: faBus,
		pattern: /^\/bus\/?$/,
		childPattern: /^\/bus\/(?!query)/,
		link: '/bus/',
		permission: 'viewBus',
	},
	{
		label: 'Bus Query',
		icon: faSearch,
		pattern: /^\/bus\/query\/?$/,
		link: '/bus/query/',
		permission: 'viewBIE',
	},
	{
		label: 'Flat Rate',
		icon: faPeopleCarry,
		pattern: /^\/oper-code\/?$/,
		childPattern: /^\/oper-code\/.+/,
		link: '/oper-code/',
		permission: 'viewLabour',
	},
	{
		label: 'DWC (Bus)',
		icon: faHandHoldingUsd,
		pattern: /^\/(warranty-claim|wc)\/?$/,
		childPattern: /^\/(warranty-claim|wc)\/.+/,
		link: '/warranty-claim/',
		permission: 'viewWC',
	},
	...(process.env.SG_ONLY ? [{
		label: 'DWC (Others)',
		icon: faHandHoldingUsd,
		link: 'http://dealers.denso.com.sg/',
		pattern: /^$/,
		external: true,
		permission: 'viewWC',
	}] as NavLinks : []),
	...(process.env.SG_ONLY ? [{
		label: 'Other Claims',
		icon: faDollarSign,
		pattern: /^\/other-claims\/?$/,
		childPattern: /^\/other-claims\/.+/,
		link: '/other-claims/',
		permission: 'viewOC',
	}] as NavLinks : []),
	{
		label: 'Users',
		icon: faUsers,
		pattern: /^\/users\/?$/,
		childPattern: /^\/users\/.+/,
		link: '/users/',
		permission: 'viewAdminUser',
	},
	{
		label: 'Usage',
		icon: faFileCsv,
		pattern: /^\/usage\/?$/,
		childPattern: /^\/usage\/.+/,
		link: '/usage/',
		permission: 'viewUserModule',
	},
	{
		label: 'Publications',
		icon: faBook,
		pattern: /^\/publication\/?$/,
		childPattern: /^\/publication\/.+$/,
		link: '/publication/',
		some: ['viewPublication', 'viewFolder'],
	},
	{
		label: 'Reports',
		icon: faFunnelDollar,
		pattern: /^\/report\/?$/,
		childPattern: /^\/report\/.+$/,
		link: '/report/',
		permission: 'viewReport',
	},
];
