import { isBoolean, isEmpty, isNil } from 'lodash';
import React, { FC, ReactNode, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Else, If, Then, When } from 'react-if';
import { Col, Row } from 'reactstrap';
import { classes } from 'typestyle';

type props = {
	title?: ReactNode;
	bigSlot?: ReactNode;
	miniSlot?: ReactNode;
	leftSlot?: ReactNode;
	rightSlot?: ReactNode;
	rowClassName?: string;
};

const isNode = (value: ReactNode): boolean => isBoolean(value) === false && isNil(value) === false && isEmpty(value) === false;

const halfClass = 'col col-6 col-lg-3 col-xl-2';
const fullClass = 'col col-12 col-lg-6 col-xl-4';

export const Toolbar: FC<props> = props => {
	const [leftEmpty, setLE] = useState(() => isNode(props.leftSlot) == false);
	const [rightEmpty, setRE] = useState(() => isNode(props.rightSlot) == false);
	const left = useRef<HTMLDivElement>(null);
	const right = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		let leftC = 0;
		left.current?.childNodes.forEach(n => n && leftC++);

		let rightC = 0;
		right.current?.childNodes.forEach(n => n && rightC++);

		const willLeftEmpty = leftC < 1;
		const willRightEmpty = rightC < 1;

		if (willLeftEmpty != leftEmpty) {
			setLE(willLeftEmpty);
		}

		if (willRightEmpty != rightEmpty) {
			setRE(willRightEmpty);
		}
	});

	const [leftClass, rightClass] = useMemo(() => {
		if (leftEmpty && rightEmpty) {
			return ['', ''];
		} else if (leftEmpty) {
			return ['', fullClass];
		} else if (rightEmpty) {
			return [fullClass, ''];
		} else {
			return [halfClass, halfClass];
		}
	}, [leftEmpty, rightEmpty]);

	return (
		<Row className={classes('d-flex justify-content-end align-items-center pt-3 mb-3', props.rowClassName)}>
			<Col>
				<When condition={!!props.title}>
					<h1 className='mb-0'>{props.title}</h1>
				</When>
				{props.bigSlot}
			</Col>

			<When condition={isNode(props.miniSlot)}>
				<Col xs='auto' className='text-right'>
					{props.miniSlot}
				</Col>
			</When>

			<div className='w-100 mb-3 d-block d-lg-none' />

			<div ref={left} className={leftClass}>
				{props.leftSlot}
			</div>

			<div ref={right} className={rightClass}>
				{props.rightSlot}
			</div>
		</Row>
	);
};

export const Old_Toolbar: FC<props> = props => {
	return (
		<Row className={classes('d-flex justify-content-end align-items-center pt-3 mb-3', props.rowClassName)}>
			<Col>
				<When condition={!!props.title}>
					<h1 className='mb-0'>{props.title}</h1>
				</When>
				{props.bigSlot}
			</Col>

			<When condition={isNode(props.miniSlot)}>
				<Col xs='auto' className='text-right'>
					{props.miniSlot}
				</Col>
			</When>

			<div className='w-100 mb-3 d-block d-lg-none' />

			<If condition={isNode(props.leftSlot) && isNode(props.rightSlot)}>
				<Then>
					<Col xs='6' lg='3' xl='2'>
						{props.leftSlot}
					</Col>
					<Col xs='6' lg='3' xl='2'>
						{props.rightSlot}
					</Col>
				</Then>

				<Else>
					<Col xs='12' lg='6' xl='4'>
						{props.leftSlot}
						{props.rightSlot}
					</Col>
				</Else>
			</If>
		</Row>
	);
};
