import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { ComponentType, createContext, FC, useContext, useLayoutEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as BS from 'reactstrap';
import styled from 'styled-components';
import { classes } from 'typestyle';
import { btnNotSoLight } from './colors';
import { fullHeightStyle } from './fullHeightStyle';
import { useUserdata, useUserPermissions } from './LoginContext';
import { NavLinks } from './NavLinks';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';

const logoSvg = require('images/Densoservice.svg');

const Navlist: ComponentType<{ onClose?: () => void; }> = ({ onClose }) => {
	const userPermissions = useUserPermissions();
	const location = useLocation();

	return (
		<BS.Nav pills className='flex-column mx-n2 mt-2'>
			{_(NavLinks)
				.filter(({ permission }) => permission == null || userPermissions[permission] === true)
				.filter(({ some }) => some == null || _.some(some, p => userPermissions[p] === true) === true)
				.map(({ link, icon, pattern, label, childPattern, external }) => (
					<BS.NavLink
						className={classes(
							pattern.test(location.pathname) ? 'bg-primary' : childPattern && childPattern.test(location.pathname) ? 'bg-secondary' : 'text-secondary'
						)}
						target={external ? '_blank' : void 7}
						active={pattern.test(location.pathname) || (childPattern && childPattern.test(location.pathname))}
						key={label}
						disabled={pattern.test(location.pathname)}
						tag={external ? 'a' : Link}
						to={link}
						href={link}
						onClick={e => {
							e.currentTarget.blur();
							onClose?.();
						}}
					>
						<FontAwesomeIcon fixedWidth icon={icon} />
						&ensp;
						<span>{label}</span>
					</BS.NavLink>
				))
				.value()}
		</BS.Nav>
	);
};

const DensoLogo = styled.img.attrs(() => ({ src: logoSvg }))`
	width: 80px;
	@media screen and (min-width: 768px) {
		width: 120px;
	}
`;

const NavbarFullWidthContext = createContext((b: boolean) => { });
NavbarFullWidthContext.displayName = 'NavbarFullWidthContext';

export const useShowingFullWidthContent = () => {
	const set = useContext(NavbarFullWidthContext);
	useLayoutEffect(() => {
		set(true);

		return () => set(false);
	}, []);
};

export const Navbar: FC = ({ children }) => {
	const [open, setOpen] = useState(false);
	const [fullWidth, setFullWidth] = useState(false);

	const userdata = useUserdata();

	const name = `${userdata.first_name} ${userdata.last_name}`;

	return (
		<>
			<BS.Modal isOpen={open} toggle={() => setOpen(b => !b)}>
				<BS.ModalBody className='pt-2'>
					{name && <BS.Button disabled color='link' className='p-0' children={name} />}
					<BS.Button color='link' className='py-0' tag='a' href='https://www.denso.com/sg/en/' target='_blank' children={<FontAwesomeIcon icon={faGlobe} />} />
					<button className='close' onClick={() => setOpen(b => !b)} children='×' />
					<br />
					<div className='w-100 pb-2' />
					<Navlist onClose={() => setOpen(b => !b)} />
				</BS.ModalBody>
			</BS.Modal>
			<BS.Container fluid className={fullHeightStyle}>
				<BS.Row>
					<BS.Col className='px-0'>
						<BS.Navbar light expand color='light'>
							<BS.Container fluid className='px-0 justify-content-start'>
								<BS.Col xs='2' className='d-lg-none'>
									<BS.Button color='link' className='pl-0' onClick={() => setOpen(b => !b)}>
										<FontAwesomeIcon fixedWidth icon={faBars} />
									</BS.Button>
								</BS.Col>
								<BS.Col xs='8' className='text-center text-lg-left px-lg-0'>
									<Link to='/'>
										<DensoLogo />
									</Link>
								</BS.Col>
								<BS.Col>
									<BS.Button color='link' className={classes(btnNotSoLight, 'float-right')} tag={Link} to='/logout'>
										<FontAwesomeIcon icon={faSignOutAlt} />
									</BS.Button>
									{name && <BS.Button disabled color='link' className='float-right d-none d-lg-block' children={name} />}
									<BS.Button color='link' className='pr-0 d-none d-lg-block  float-right' tag='a' href='https://www.denso.com/sg/en/' target='_blank' children={<FontAwesomeIcon icon={faGlobe} />} />
								</BS.Col>
							</BS.Container>
						</BS.Navbar>
					</BS.Col>
				</BS.Row>
				<BS.Row className={fullHeightStyle}>
					<BS.Col md='3' lg='3' xl='2' className='bg-light d-none d-lg-block'>
						<Navlist />
					</BS.Col>
					<BS.Col lg='9' xl='10' className={classes('py-2 mx-auto', !fullWidth && 'px-lg-3 px-xl-5')} style={fullWidth ? {} : { maxWidth: 1280 }}>
						<NavbarFullWidthContext.Provider value={b => setFullWidth(b)}>
							{children}
						</NavbarFullWidthContext.Provider>
					</BS.Col>
				</BS.Row>
			</BS.Container>
		</>
	);
};
