import * as Yup from 'yup';
import { ReactNodeArray, useLayoutEffect } from 'react';
import { checkForErrors } from 'core-components/useValidation';
import _ from 'lodash';
import { observable, action } from 'mobx';
import { attachDate } from 'util/dateConvert';

// ===== validation ============================================================
// ===== validation ============================================================
// ===== validation ============================================================

type validatableState = {
	schema?: Yup.Schema<string>;
	validation?: Validation.Array;
	errors: ReactNodeArray;
	value: string;
};

export const useXValidate = (current: validatableState) => {
	useLayoutEffect(() => {
		xValidate(current);
	}, [current.value]);
};

export const xValidate = (current: validatableState) => {
	if (current.schema) {
		try {
			current.schema.validateSync(current.value);
			current.errors = [];
		} catch (e) {
			console.log(e)
			//const validation: Yup.ValidationError = e;
			//current.errors = validation.errors;
		}
	} else {
		current.errors = checkForErrors(current.value, current.validation);
	}
};

type SchemaType = Yup.StringSchema<string>;

// ===== state (class version) =================================================
// ===== state (class version) =================================================
// ===== state (class version) =================================================

export type textTransformer = (value: string) => string;

export type xBaseSetup = {
	value?: string;
	errors?: ReactNodeArray;

	schema?: SchemaType;
	$disabled?: boolean;
	$touched?: boolean;
	$postTransform?: textTransformer[];
};

export class xBaseState {
	@observable value: string = '';
	@observable errors: ReactNodeArray = [];
	@observable $disabled?: boolean;
	@observable $touched?: boolean;

	@observable schema?: SchemaType;
	@observable $postTransform?: textTransformer[];

	constructor(setup?: xBaseSetup) {
		if (setup) {
			_.assign(this, _.pick(setup, ['value', 'errors', 'schema', '$disabled', '$touched']));
			
			if (setup.$postTransform) {
				if (this.$postTransform) {
					this.$postTransform = this.$postTransform.concat(setup.$postTransform);
				} else {
					this.$postTransform = setup.$postTransform;
				}
			}
		}
	}

	$clone(): this {
		return _.cloneDeep(this);
	}

	@action $applyPostTransform(): this {
		if (this.errors.length < 1 && this.$postTransform) {
			this.value = _.flow(this.$postTransform)(this.value);
		}
		return this;
	}

	@action $addSchema(schema: SchemaType): this {
		this.schema = this.schema ? this.schema.concat(schema) : schema;
		return this;
	}

	@action $addPostTransform(...transforms: textTransformer[]): this {
		this.$postTransform = transforms.concat(this.$postTransform || []);
		return this;
	}

	@action $setValue(value: string | undefined): this {
		this.value = value || '';
		return this;
	}

	@action $attachDate(value: string | undefined): this {
		this.value = attachDate(value);
		return this;
	}

	@action $disable($disabled = true): this {
		this.$disabled = $disabled;
		return this;
	}
}

// ===== state (old deprecated version) ========================================
// ===== state (old deprecated version) ========================================
// ===== state (old deprecated version) ========================================

export interface xBaseDefaultStateType<T extends xBaseDefaultStateType = any> {
	value: string;
	validation: Validation.Array;
	schema?: SchemaType;
	errors: ReactNodeArray;
	$disabled?: boolean;
	$touched?: boolean;
	$postTransform?: ((value: string) => string)[];

	$applyPostTransform: () => T;

	$addValidation: (...validation: Validation.Array) => T;
	$addSchema: (schema: SchemaType) => T;
	$addPostTransform: (...transforms: ((value: string) => string)[]) => T;

	$withValue: (value: string) => T;
	$makeDisabled: ($disabled?: boolean) => T;
}

export const xBaseDefaultState: xBaseDefaultStateType = {
	value: '',
	validation: [],
	errors: [],

	$applyPostTransform() {
		if (this.errors.length < 1 && this.$postTransform) {
			this.value = _.flow(this.$postTransform)(this.value);
		}
		return this;
	},

	$addValidation(...validation) {
		return { ...this, validation: this.validation.concat(validation) };
	},

	$addSchema(schema) {
		return { ...this, schema: this.schema ? this.schema.concat(schema) : schema };
	},

	$addPostTransform(...transforms) {
		return {
			...this,
			$postTransform: transforms.concat(this.$postTransform || []),
		};
	},

	$withValue(value) {
		return { ...this, value };
	},
	$makeDisabled($disabled = true) {
		return { ...this, $disabled };
	},
};
