import { thru } from 'lodash';
import moment from 'moment';

export const dateFormatForState = 'DD/MM/YYYY';
export const dateFormatForServer = 'YYYY-MM-DD';

export const detachDate = (value: string | undefined) =>
	thru(moment(value, dateFormatForState), date => (date.isValid() ? date.format(dateFormatForServer) : ''));
export const attachDate = (value: string | undefined) =>
	thru(moment(value, dateFormatForServer), date => (date.isValid() ? date.format(dateFormatForState) : ''));

export const newDateFromSever = (value: string | undefined) => moment(value, dateFormatForServer);
export const newDateFromState = (value: string | undefined) => moment(value, dateFormatForState);

export const todayStrForState = () => moment().format(dateFormatForState);
