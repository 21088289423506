import * as Yup from 'yup';

export const vRequired: Validation.Object = {
	pattern: /^.+$/,
	message: 'Please fill in.',
};
export const vNumber: Validation.Object = {
	pattern: /^[0-9]*$/,
	message: 'Please only use numbers.',
};
export const vDate: Validation.Object = {
	pattern: /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
	message: 'Please enter a valid date.',
};
export const vMoney: Validation.Object = {
	pattern: /^([0-9]+(\.[0-9]+)?)?$/,
	message: 'Please enter a valid amount.',
};

export const vRequiredSelect: Validation.Object = {
	pattern: /^.+$/,
	message: 'Please select one.',
};

export const yRequired = Yup.string().required('Please fill in.');
export const yRequiredSelect = Yup.string().required('Please select one.');
export const yNumber = Yup.string().matches(/^[0-9]*$/, 'Please enter a valid number.');
export const yDecimal = Yup.string().matches(/^([0-9]+(\.[0-9]+)?)?$/, 'Please enter a valid decimal.');
export const yMoney = Yup.string().matches(/^([0-9]+(\.[0-9]+)?)?$/, 'Please enter a valid amount.');
export const yDate = Yup.string().matches(/^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/, 'Please enter a valid date.');
export const yRequiredDate = Yup.string().matches(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/, 'Please select a date.');
export const yRequiredFileFolderName = yRequired
	.matches(/^[a-zA-Z0-9\.\-\_ ]+$/, 'Please use only valid characters: spaces, letters (A-Z a-z), digits (0-9), periods (.), underscores (_), and hyphens (-).')
	.matches(/^[^\. ]/, 'Please start with a valid character: letter (A-Z a-z), digit (0-9), underscore (_), or hyphen (-).')
	.matches(/[^\. ]$/, 'Please end with a valid character: letter (A-Z a-z), digit (0-9), underscore (_), or hyphen (-).')
	;