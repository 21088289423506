import { useEffect, useRef, useState } from 'react';
import { getDebounce } from 'util/getDebounce';
import { APIRes, getHeaders, postHeaders } from './API_Common';

export function apiCall<Data extends any = undefined, Others extends {} = {}>(
	method: 'GET',
	url: string,
	params?: Record<string, string | number>,
): APIRes<Data, Others>;

export function apiCall<Data extends any = undefined, Others extends {} = {}>(
	method: 'POST' | 'PUT',
	url: string,
	body?: any,
	options?: {
		sendRaw?: boolean;
	},
): APIRes<Data, Others>;

export async function apiCall(method: 'GET' | 'POST' | 'PUT', url: string, bodyOrParams?: any, { sendRaw = false } = {}) {
	try {
		const completeUrl = new URL(process.env.BASE_PATH + url.replace(/\/$/, '/'), window.location.origin);
		/*console.log("process.env.BASE_PATH + url.replace(/\/$/, '/')", process.env.BASE_PATH + url.replace(/\/$/, '/'))
		/staging-sg/api/labour/all/
		/staging-sg/api/wc/
		/staging-sg/api/cost-centre/
		/staging-sg/api/replacement-part/
		/staging-sg/api/wc-edit/
		*/
		if (method === 'GET' && bodyOrParams) {
			completeUrl.search = new URLSearchParams(bodyOrParams).toString();
		}

		const headers = (() => {
			if (method === 'GET' || sendRaw) return getHeaders;
			return postHeaders;
		})();

		const body = (() => {
			if (method === 'GET') return void 7;
			if (sendRaw) return bodyOrParams;
			return JSON.stringify(bodyOrParams);
		})();

		const res = await fetch(completeUrl.toString(), {
			method,
			headers,
			body,
		});
		if (res.status === 403) {
			window.location.href = '/logout';
			return null;
		}
		if (res.ok) return await res.json();
	} catch (e) {
		console.error(e);
	}
	return null;
}

type DataRefresher = (params?: Record<string, string | number>) => void;
type Loading = boolean;



export function useApiGet<Data extends any = undefined, Others extends {} = {}>(
	url: string,
	params?: Record<string, string | number>,
	lazyLoad = false
): [Loading, Unwrap<APIRes<Data, Others>> | null, DataRefresher] {
	const [state, setState] = useState<Unwrap<APIRes<Data, Others>> | null>(null);
	const [loading, setLoading] = useState(false);
	const prevCancel = useRef({ cancelled: false });


	async function refresh(newParams = params) {
		prevCancel.current.cancelled = true;
		const myCancel = { cancelled: false };
		prevCancel.current = myCancel;

		const debounce = getDebounce();
		debounce(() => setLoading(true));

		const res = await apiCall<Data, Others>('GET', url, newParams);
		debounce(() => {
			if (myCancel.cancelled == false) {
				setState(res);
				setLoading(false);
			}
		});
	}

	useEffect(() => {
		lazyLoad || refresh();
	}, []);

	return [loading, state, refresh];
}