import { Route, Redirect } from 'react-router-dom';
import React, { FC } from 'react';
import { Toolbar } from './Toolbar';

export const Fallback404: FC = () => (
	<Route>
		{/* <Redirect to='/404' /> */}
		<Toolbar title='Not Found' />
	</Route>
);

export const Page404: FC = () => <Toolbar title='Not Found' />;
